import React from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
function Footer() {
  const Agent = useSelector((state) => state.layouts.userState);

  return (
    <div className="footer">
      <div
        className="wrapper"
        style={{ textAlign: Agent === "MB" ? "center" : "left" }}
      >
        {Agent === "MB" ? (
          <img src="/assets/main/footer_mb.svg" alt="로고" />
        ) : undefined}
        <div className="content">
          아이퍼즐 | 사업자등록번호 121-43-61446 | 대표 곽창섭 <br /> 경상북도
          경산시 대구대로 201 대구대학교 창업보육센터{" "}
          {Agent === "MB" ? <br /> : undefined} 1202E | ipuzzle @naver.com
        </div>
      </div>
    </div>
  );
}

export default Footer;

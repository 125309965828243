import React from "react";
import "./css/index.css";
function Section9() {
  return (
    <div
      className="section9"
      style={{ backgroundImage: `url(/assets/main/s9_back.png)` }}
    >
      <div className="wrapper">
        <div className="content">
          아이퍼즐과 함께
          <br />
          추억을 함께
          <br />
          <div className="sub">
            아이퍼즐과 함께 가족과의 <br /> 소중한 추억을 함께 나누어보세요
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section9;
